.Card {
  overflow: auto;
}

.Chart {
  min-width: 700px;
  max-width: 900px;
  margin: 0 auto;
}

.Overlay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(256, 256, 256, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
}

ol,
ul {
  margin-top: 1em;
  margin-bottom: 1em;
}
